import toolsServices from '@/services/tools.services'
import clientMQTT from '@/services/mqtt'

export default {
  state: {
    RecievedMessage: [],
    Connected: false,
  },
  getters: {
    getMqttRecieved: (state) => state.RecievedMessage,
  },
  mutations: {
    cleanMqttRecieved(state) {
      state.RecievedMessage = []
    },
    addMessage(state, message) {
      state.RecievedMessage.push(message)
      while (state.RecievedMessage.length > 60) {
        state.RecievedMessage.shift()
      }
    },
    nothing(state) {
      console.log(state)
    },
  },
  actions: {
    mqttConnect({ commit }) {
      // setup the callbacks
      if (!this.Connected) {
        console.log(this.Connected)
        console.log('mqtt Try Connect')
        clientMQTT.clientMQTT.on('connect', () => console.log('Connected'))
        clientMQTT.clientMQTT.on('error', (error) => console.log(error))
        clientMQTT.clientMQTT.on('message', (topic, message) => {
          console.log((topic, message))
          commit('addMessage', { topic, message: message.toString(), t: Date() })
        }) // console.log('Received message:', topic, message.toString()));
        this.Connected = true
        console.log(`is mqtt connected: ${this.Connected}`)
      }
      // clientMQTT.clientMQTT.subscribe('counter/#');
    },
    mqttSub({ commit }, topic) {
      console.log(`mqttSub  ${topic}`)
      //  console.log(clientMQTT.clientMQTT);
      clientMQTT.clientMQTT.subscribe(topic)
      commit('nothing', 'mqttSub')
    },
    mqttUnSub({ commit }, topic) {
      console.log(`mqttUnSub  ${topic}`)
      clientMQTT.clientMQTT.unsubscribe(topic)
      commit('nothing', 'mqttUnSub')
    },
    mqttPub({ commit }, { topic, message }) {
      console.log(`mqttPub  ${topic}`)
      clientMQTT.clientMQTT.publish(topic, message)
      commit('nothing', 'mqttPub')
    },
    // eslint-disable-next-line no-empty-pattern
    async requestParcelReturnLabel({}, payload) {
      const { data: message } = await toolsServices.requestParcelReturnLabel(payload)
      return message
    },
    // eslint-disable-next-line no-empty-pattern
    async printLabelCounter({}, { idCounter, count }) {
      const { data: message } = await toolsServices.printLabelCounter({ idCounter, count })
      return message
    },
    // eslint-disable-next-line no-empty-pattern
    async setCounterInShipMode({}, { idCounter }) {
      const { data: message } = await toolsServices.setCounterInShipMode({ idCounter })
      return message
    },

    // eslint-disable-next-line no-empty-pattern
    async setCanMinUsPeriod({}, { idCounter, minUsPeriod }) {
      const { data: message } = await toolsServices.setCanMinUsPeriod({ idCounter, minUsPeriod })
      return message
    },
    // eslint-disable-next-line no-empty-pattern
    async setImuRecords({}, { idCounter, enable }) {
      const { data: message } = await toolsServices.setImuRecords({ idCounter, enable })
      return message
    },
    // eslint-disable-next-line no-empty-pattern
    async setEsimTimout({}, { idCounter, esimTimout }) {
      const { data: message } = await toolsServices.setEsimTimout({ idCounter, esimTimout })
      return message
    },
    //  async setCounterInShipMode({ }, idCounter){
    //    const { data: message } = await toolsServices.setCounterInShipMode(idCounter);
    //    return message;
    //   }
  },
}
