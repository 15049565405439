import CanbusJ1939MomentaryServices from '../services/canbusJ1939Momentary.services';

export default {
  state: {
    canbusJ1939: [],
  },
  getters: {
    canbusJ1939: (state) => state.canbusJ1939,
  },
  mutations: {
    setCanbusJ1939(state, data) {
      state.canbusJ1939 = [...data];
    },
  },
  actions: {
    async getHistoricalDatasCanbusJ1939({ commit }, {
      idCounter, startDate, endDate, limit, page,
    }) {
      const { data } = await CanbusJ1939MomentaryServices.getHistoricalDatasCanbusJ1939({
        idCounter,
        startDate,
        endDate,
        limit,
        page,
      });
      commit('setCanbusJ1939', data);
    },
  },
};
