import Api from '@/services/Api';

export default {
  getCountersCluster(idCounter) {
    return Api().get('countersCluster', {
      params: {
        idCounter,
      },
    });
  },
  addCountersCluster(countersClusters) {
    console.log('j appell l api addCountersCluster');
    return Api().post('countersCluster', countersClusters);
  },
};
