import Api from '@/services/Api';

export default {
  getHistoricalDatas({
    idCounter, startDate, endDate, limit, page,
  }) {
    return Api().get('countersHistoricalDatas', {
      params: {
        idCounter, startDate, endDate, limit, page,
      },
    });
  },
  getLastDataDate(idCounter) {
    return Api().get('lastDataDate', {
      params: {
        idCounter,
      },
    });
  },
};
