import Api from '@/services/Api';

export default {
  downloadRawData({
    idCounter, startDate, endDate, type,
  }) {
    return Api().get('exports/rawData', {
      params: {
        idCounter, startDate, endDate, type,
      },
      responseType: 'blob',
    });
  },
};
