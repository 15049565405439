import membersClustersServices from '../services/membersClusters.services';

export default {
  state: {
    members: [],
  },
  getters: {
    members: (state) => state.members,
  },
  mutations: {
    setMembers(state, members) {
      state.members = [...members];
    },
  },
  actions: {
    async getMembersClusters({ commit }, idCluster) {
      commit('setMembers', []);
      const { data: members } = await membersClustersServices.getMembersClusters(idCluster);
      commit('setMembers', members);
    },
    async addMembersClusters({ commit }, { idCluster, emails, rules }) {
      const { data: members } = await membersClustersServices.addMembersClusters({
        idCluster,
        emails,
        rules,
      });
      commit('setMembers', members);
    },
  },
};
