import meServices from '@/services/me.services'

export default {
  state: {
    me: {},
  },
  getters: {
    me: (state) => state.me,
    adminGroups: (state) => state.me.adminGroups,
    isSamsys: (state) => {
      if (state.me.adminGroups) {
        return !!state.me.adminGroups.find((elem) => elem.id === '5f05e458c9b648fc7a717125')
      }
      return false
    },
  },
  mutations: {
    setMe(state, me) {
      state.me = me
    },
  },
  actions: {
    async getMe({ commit }) {
      const { data: me } = await meServices.getMe()
      commit('setMe', me)
    },
  },
}
