export default {
  state: {
    message: '',
    color: 'info',
    open: false,
  },
  getters: {
    getSnackbarStatus: (state) => state.open,
    getSnackbarInfos: (state) => ({
      message: state.message,
      color: state.color,
    }),
  },
  mutations: {
    setSnackbarInfos(state, payload) {
      state.message = payload.message;
      state.color = payload.color || 'info';
      state.open = true;
    },
    setSnackbarStatus(state, payload) {
      state.open = payload;
    },
  },
  actions: {
    setSnackbarInfos({ commit }, payload) {
      commit('setSnackbarInfos', payload);
    },
    setSnackberStatus({ commit }, payload) {
      commit('setSnackbarStatus', payload);
    },
  },
};
