import moment from 'moment';
import canbusValuesServices from '../services/canbusValues.services';

export default {
  state: {
    canbusValues: {},
  },
  getters: {
    canbusValues: (state) => {
      const { canbusValuesConfig, datas } = state.canbusValues;
      const locale = moment.locale();
      if (canbusValuesConfig && datas) {
        const nameAndUnit = canbusValuesConfig.map((elem) => {
          let name = elem.name.find((tps) => tps.languages.includes(locale));
          let unit = elem.unit.find((tps) => tps.languages.includes(locale));

          if (name === undefined) {
            name = elem.name.find((tps) => tps.languages.includes('default'));
          }

          if (unit === undefined) {
            unit = elem.unit.find((tps) => tps.languages.includes('default'));
          }
          return {
            id: elem.id,
            name,
            unit,
          };
        });
        return datas.map((elem) => {
          const nAU = nameAndUnit.find((test) => test.id === elem.value_id);
          let name = '';
          if (nAU) {
            name = `${nAU.name?.text} ${nAU.unit?.text}`;
          }

          return {
            loc: elem.loc,
            value: elem.value,
            t_data: elem.t_data,
            name,
          };
        });
      }
      return [];
    },
  },
  mutations: {
    setCanbusValues(state, data) {
      state.canbusValues = { ...data };
    },
  },
  actions: {
    async getCanbusValues({ commit }, {
      idCounter, startDate, endDate, limit, page,
    }) {
      const { data } = await canbusValuesServices.getCanbusValues({
        idCounter,
        startDate,
        endDate,
        limit,
        page,
      });
      commit('setCanbusValues', data);
    },
  },
};
