import canbusValuesConfigsServices from '../services/canbusValuesConfigs.services';

export default {
  state: {
    canbusValuesConfigs: [],
  },
  getters: {
    canbusValuesConfigs: (state) => state.canbusValuesConfigs,
  },
  mutations: {
    setCanbusValuesConfigs(state, configs) {
      state.canbusValuesConfigs = [...configs];
    },
  },
  actions: {
    async getCanbusValuesConfigs({ commit }, { idCounter }) {
      const { data: configs } = await canbusValuesConfigsServices.getCanbusValuesConfigs({
        idCounter,
      });
      commit('setCanbusValuesConfigs', configs);
    },
    // eslint-disable-next-line no-empty-pattern
    async setCanbusValuesConfigs({}, { counters, idCanbusValuesConfig }) {
      const response = await canbusValuesConfigsServices.setCanbusValuesConfigs({
        counters,
        idCanbusValuesConfig,
      });
      return response;
    },
  },
};
