import Api from '@/services/Api';

export default {
  getCanbusCounterConfigs({ idCounter }) {
    return Api().get('canbusCounterConfigs', {
      params: {
        idCounter,
      },
    });
  },
  setCanbusCounterConfigs({ counters, idCanbusConfig }) {
    return Api().post('canbusCounterConfigs', {
      counters,
      idCanbusConfig,
    });
  },
};
