import canbusCounterConfigsServices from '../services/canbusCounterConfigs.services';

export default {
  state: {
    canbusCounterConfigs: [],
  },
  getters: {
    canbusCounterConfigs: (state) => state.canbusCounterConfigs,
  },
  mutations: {
    setCanbusCounterConfigs(state, configs) {
      state.canbusCounterConfigs = [...configs];
    },
  },
  actions: {
    async getCanbusCounterConfigs({ commit }, { idCounter }) {
      const { data: configs } = await canbusCounterConfigsServices.getCanbusCounterConfigs({
        idCounter,
      });
      commit('setCanbusCounterConfigs', configs);
    },
    // eslint-disable-next-line no-empty-pattern
    async setCanbusCounterConfigs({}, { counters, idCanbusConfig }) {
      const response = await canbusCounterConfigsServices.setCanbusCounterConfigs({
        counters,
        idCanbusConfig,
      });
      return response;
    },
  },
};
