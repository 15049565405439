import Api from '@/services/Api';

export default {
  getHistoryDatas(idCounter) {
    return Api().get('countersHistoryData', {
      params: {
        idCounter,
      },
    });
  },
};
