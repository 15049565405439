import Api from '@/services/Api';

export default {
  getMembersClusters(idCluster) {
    return Api().get('membersCLusters', {
      params: {
        idCluster,
      },
    });
  },
  addMembersClusters({ idCluster, emails, rules }) {
    return Api().post('membersCLusters', {
      idCluster, emails, rules,
    });
  },
};
