import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const tryRoute = (to, from, next) => {
  const token = store.getters.getAdminToken
  if (token && to.path === '/login') {
    next('/')
  } else if (!token && !(to.path === '/login' || to.path === '/reset')) {
    next(`/login?goto=${encodeURIComponent(to.fullPath)}`)
  } else {
    next()
  }
}

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/login/login.vue'),
  },
  {
    path: '/reset',
    name: 'ResetPassword',
    component: () => import(/* webpackChunkName: "reset" */ '@/views/ResetPassword.vue'),
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: "menu" */ '@/views/Menu.vue'),
    children: [
      {
        path: '',
        name: 'dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
      },
      {
        path: 'counters',
        name: 'counters',
        component: () => import(/* webpackChunkName: "counters" */ '@/views/Counters.vue'),
      },
      {
        path: 'maps',
        name: 'maps',
        component: () => import(/* webpackChunkName: "maps" */ '@/views/Maps.vue'),
      },
      {
        path: 'params',
        name: 'params',
        component: () => import(/* webpackChunkName: "params" */ '@/views/Params.vue'),
      },
      {
        path: 'users',
        name: 'users',
        component: () => import(/* webpackChunkName: "users" */ '@/views/Users.vue'),
      },
      {
        path: 'clusters',
        name: 'clusters',
        component: () => import(/* webpackChunkName: "clusters" */ '@/views/Clusters.vue'),
      },
      {
        path: 'bleTags',
        name: 'bleTags',
        component: () => import(/* webpackChunkName: "bleTags" */ '@/views/BleTags.vue'),
      },
      {
        path: 'locations',
        name: 'locations',
        component: () => import(/* webpackChunkName: "bleTags" */ '@/views/countersLocation.vue'),
      },
      {
        path: 'tools',
        name: 'tools',
        component: () => import(/* webpackChunkName: "tools" */ '@/views/Tools.vue'),
      },
      {
        path: 'renewBot',
        name: 'renewBot',
        component: () => import(/* webpackChunkName: "renewBot" */ '@/views/renewBot.vue'),
      },
      {
        path: 'invoicing',
        name: 'invoicing',
        component: () => import(/* webpackChunkName: "invoices" */ '@/views/InvoicingMain.vue'),
        redirect: { name: 'invoicing.Counters' },
        children: [
          {
            path: 'counters',
            name: 'invoicing.Counters',
            props: true,
            component: () =>
              import(/* webpackChunkName: "invoices" */ '@/views/invoicing/Invoices.vue'),
          },
          {
            path: 'billingAccounts',
            name: 'invoicing.BillingAccounts',
            props: true,
            component: () =>
              import(/* webpackChunkName: "invoices" */ '@/views/invoicing/billingAccounts.vue'),
          },
        ],
      },
      {
        path: 'machines',
        name: 'machines',
        component: () => import(/* webpackChunkName: "machines" */ '@/views/Machines.vue'),
      },
    ],
  },
  {
    path: '/counters/:id',
    props: true,
    component: () => import(/* webpackChunkName: "counters" */ '@/views/CountersDetails.vue'),
    children: [
      {
        path: '',
        name: 'counterInfosMains',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "counters" */ '@/views/CountersDetails/CountersDetailsInfos.vue'
          ),
      },
      {
        path: 'datas',
        name: 'contersDataMains',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "counters" */ '@/views/CountersDetails/CountersDetailsDatas.vue'
          ),
      },
      {
        path: 'can',
        name: 'contersCanMains',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "counters" */ '@/views/CountersDetails/CountersDetailsCan.vue'
          ),
      },
      {
        path: 'rights',
        name: 'contersRightsMains',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "counters" */ '@/views/CountersDetails/CountersDetailsRights.vue'
          ),
      },

      {
        path: 'bleScan',
        name: 'contersbleScanMains',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "counters" */ '@/views/CountersDetails/CountersDetailsBleScan.vue'
          ),
      },
    ],
  },
  {
    path: '/clusters/:id',
    props: true,
    component: () => import(/* webpackChunkName: "clusters" */ '@/views/ClustersDetails.vue'),
    children: [
      {
        path: '',
        name: 'clusterInfosMains',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "clusters" */ '@/views/ClustersDetails/ClustersDetailsInfos.vue'
          ),
      },
      {
        path: 'members',
        name: 'clusterMembersMains',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "clusters" */ '@/views/ClustersDetails/ClustersDetailsMembers.vue'
          ),
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes,
})

router.beforeEach(tryRoute)

export default router
