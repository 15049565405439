import Api from '@/services/Api';

export default {
  getCountersMachines(idCounter) {
    return Api().get('countersMachines', {
      params: {
        idCounter,
      },
    });
  },
};
