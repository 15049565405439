import Api from '@/services/Api';

export default {
  getClusters() {
    return Api().get('clusters');
  },
  getClustersInfos(idCluster) {
    return Api().get(`clusters/${idCluster}`);
  },
  createClusters(cluster) {
    return Api().post('clusters', cluster);
  },
};
