import Api from '@/services/Api';

export default {
  getCounters(params) {
    return Api().get('counters', { params });
  },
  getCountersLight() {
    return Api().get('counters/simpleList');
  },
  getCountersInfos(idCounter) {
    return Api().get(`counters/${idCounter}`);
  },
  setCountersInfos(idCounter, payload) {
    return Api().put(`counters/${idCounter}`, payload);
  },
  addCountersInvoice(countersClusters) {
    console.log('j appell l api addCountersInvoice');
    // console.log(Api().post('counters/Invoices', countersClusters));
    return Api().post('counters/Invoices', countersClusters);
  },
  addBillingLicences(billingLicence) {
    console.log(billingLicence);
    console.log('j appell l api BillingLicencew');
    return Api().post('counters/billingLicence', billingLicence);
  },
};
