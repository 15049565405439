import Api from '@/services/Api';

export default {
  requestParcelReturnLabel(payload) {
    return Api().post('tools/parcelReturnLabel', payload);
  },
  printLabelCounter({ idCounter, count }) {
    return Api().post('tools/printer', { idCounter, count });
  },
  setCounterInShipMode({ idCounter }) {
    return Api().post('tools/shipMode', { idCounter });
  },
  setCanMinUsPeriod({ idCounter, minUsPeriod }) {
    return Api().post('tools/canMinUsPeriod', { idCounter, minUsPeriod });
  },
  setImuRecords({ idCounter, enable }) {
    return Api().post('tools/imuRecord', { idCounter, enable });
  },
  setEsimTimout({ idCounter, esimTimout }) {
    return Api().post('tools/EsimTimout', { idCounter, esimTimout });
  },
};
