import Vue from 'vue';
import Vuex from 'vuex';
import auth from './auth';
import infoSnackbar from './infoSnackbar';
import counters from './counters';
import clustersStore from './clusters.store';
import counterDatasStore from './counterDatas.store';
import meStore from './me.store';
import countersAssociationsStore from './countersAssociations.store';
import canbusCounterConfigsStore from './canbusCounterConfigs.store';
import paramsStore from './params.store';
import usersStore from './users.store';
import canbusValuesConfigsStore from './canbusValuesConfigs.store';
import canbusValuesStore from './canbusValues.store';
import lastCanbusValuesStore from './lastCanbusValues.store';
import iccidStore from './iccid.store';
import iccidUsageStore from './iccidUsage.store';
import toolsStore from './tools.store';
import membersClustersStore from './membersClusters.store';
import canbusJ1939MomentaryStore from './canbusJ1939Momentary.store';
import canbusRawStore from './canbusRaw.store';
import exportsStore from './exports.store';
// import billingCountersStore from './billingCounters.store';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    infoSnackbar,
    counters,
    clustersStore,
    counterDatasStore,
    meStore,
    countersAssociationsStore,
    canbusCounterConfigsStore,
    paramsStore,
    usersStore,
    canbusValuesConfigsStore,
    canbusValuesStore,
    lastCanbusValuesStore,
    iccidStore,
    iccidUsageStore,
    toolsStore,
    membersClustersStore,
    canbusJ1939MomentaryStore,
    exportsStore,
    // billingCountersStore,
    canbusRawStore,
  },
});
