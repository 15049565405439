import usersServices from '@/services/users.services';

export default {
  state: {
    users: [],
  },
  getters: {
    users: (state) => state.users,
  },
  mutations: {
    setUsers(state, users) {
      state.users = users;
    },
  },
  actions: {
    async getAllUsers({ commit }) {
      const { data: users } = await usersServices.getAllUsers();
      commit('setUsers', users);
    },
  },
};
