<template>
  <v-app>
    <router-view class="fulls"/>
    <snackbar />
  </v-app>
</template>

<script>
import Snackbar from '@/components/infos/Snackbar.vue';

export default {
  name: 'App',
  components: {
    Snackbar,
  },
};
</script>

<style>
.fulls {
  height: 100%;
  background-color: #f7f7f7;
}
</style>
