import exportsService from '../services/exports.services';

export default {
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    // eslint-disable-next-line no-empty-pattern
    async downloadRawData({ }, {
      idCounter, startDate, endDate, type,
    }) {
      const { data } = await exportsService.downloadRawData({
        idCounter,
        startDate,
        endDate,
        type,
      });
      return data;
    },
  },
};
