<template>
  <v-snackbar v-model="errorSnackbar" :color="getSnackbarInfos.color" right top>
    {{ getSnackbarInfos.message }}
    <template v-slot:action="{ attrs }">
      <v-btn dark text v-bind="attrs" @click="errorSnackbar = false">Close</v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'snackbar',
  data() {
    return {
      errorMessage: 'test',
    };
  },
  methods: {
    ...mapActions(['setSnackberStatus']),
  },
  computed: {
    ...mapGetters(['getSnackbarStatus', 'getSnackbarInfos']),
    errorSnackbar: {
      get() {
        return this.getSnackbarStatus;
      },
      set(newValue) {
        return this.setSnackberStatus(newValue);
      },
    },
  },
};
</script>
