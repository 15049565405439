import Api from '@/services/Api';

export default {
  getCountersDiagramDatas(idCounter, range) {
    console.log(range);
    return Api().get('countersDiagramDatas', {
      params: {
        idCounter,
        ...range,
      },
    });
  },
};
