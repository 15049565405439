import paramsServices from '@/services/params.services';

export default {
  state: {
    themes: [],
    canbusconfigs: [],
    canbusvaluesconfigs: [],
    adminAccount: [],
    firmwareList: [],
    countersAdminRights: [],
  },
  getters: {
    themes: (state) => state.themes,
    canbusconfigs: (state) => state.canbusconfigs,
    canbusvaluesconfigs: (state) => state.canbusvaluesconfigs,
    adminAccount: (state) => state.adminAccount,
    firmwareList: (state) => state.firmwareList,
    countersAdminRights: (state) => state.countersAdminRights,
  },
  mutations: {
    setThemes(state, themes) {
      state.themes = themes;
    },
    addTheme(state, theme) {
      state.themes.push(theme);
    },
    setCanbusConfigs(state, configs) {
      state.canbusconfigs = configs;
    },
    addCanbusConfig(state, config) {
      state.canbusconfigs.unshift(config);
    },
    addCanbusValuesConfig(state, config) {
      state.canbusvaluesconfigs.unshift(config);
    },
    setCanbusValuesConfig(state, configs) {
      state.canbusvaluesconfigs = configs;
    },
    addAdminAccount(state, account) {
      state.adminAccount.push(account);
    },
    setAdminAccounts(state, accounts) {
      state.adminAccount = accounts;
    },
    setFirmwareLists(state, firmwareList) {
      state.firmwareList = firmwareList;
    },
    setCountersAdminRights(state, countersAdminRights) {
      state.countersAdminRights = countersAdminRights;
    },
    editCountersAdminRights(state, { idAdmin, counter, accessLevel }) {
      const tps = state.countersAdminRights
        .find((elem) => elem.id === idAdmin && elem.counter === counter);
      if (tps) {
        tps.access_level = accessLevel;
      }
    },
    addCountersAdminRights(state, rights) {
      state.countersAdminRights.push(...rights.map((elem) => ({
        id: elem.idAdmin,
        name: elem.name,
        counter: elem.counter,
        access_level: elem.access_level,
      })));
    },
    deleteCountersAdminRights(state, { idAdmin, counter }) {
      const index = state.countersAdminRights
        .findIndex((elem) => (elem.id === idAdmin && elem.counter === counter));
      console.log(index, idAdmin, counter);
      if (index !== -1) {
        state.countersAdminRights.splice(index, 1);
      }
    },
  },
  actions: {
    async getThemes({ commit }) {
      const { data: themes } = await paramsServices.getThemes();
      commit('setThemes', themes);
    },
    async createTheme({ commit }, theme) {
      const { data: themeResp } = await paramsServices.createTheme(theme);
      commit('addTheme', themeResp);
      return themeResp;
    },
    // eslint-disable-next-line no-empty-pattern
    async updateTheme({ }, { id, theme }) {
      await paramsServices.updateTheme(id, theme);
    },
    // eslint-disable-next-line no-empty-pattern
    async setThemeToUsers({}, payload) {
      await paramsServices.setThemeToUsers(payload);
    },
    async createCanbusConfig({ commit }, config) {
      const { data: configResp } = await paramsServices.createCanbusConfig(config);
      commit('addCanbusConfig', configResp);
      return configResp;
    },
    async getCanbusConfig({ commit }) {
      const { data: canbusConfigs } = await paramsServices.getCanbusConfig();
      commit('setCanbusConfigs', canbusConfigs);
      return canbusConfigs;
    },
    async createCanbusValuesConfig({ commit }, config) {
      const { data: configResp } = await paramsServices.createCanbusValuesConfig(config);
      commit('addCanbusValuesConfig', configResp);
      return configResp;
    },
    async getCanbusValuesConfig({ commit }) {
      const { data: canbusValuesConfigs } = await paramsServices.getCanbusValuesConfig();
      commit('setCanbusValuesConfig', canbusValuesConfigs);
      return canbusValuesConfigs;
    },
    async createAdminAccount({ commit }, account) {
      const { data: accountResp } = await paramsServices.createAdminAccount(account);
      commit('addAdminAccount', accountResp);
      return accountResp;
    },
    async getAdminAccounts({ commit }) {
      const { data: accountsResp } = await paramsServices.getAdminAccounts();
      commit('setAdminAccounts', accountsResp);
      return accountsResp;
    },
    // eslint-disable-next-line no-empty-pattern
    async addEmailInAdminAccounts({ }, { id, emails }) {
      await paramsServices.addEmailInAdminAccounts(id, emails);
    },
    async getFirmwareLists({ commit }) {
      const { data: firmwareList } = await paramsServices.getFirmwareList();
      commit('setFirmwareLists', firmwareList);
      return firmwareList;
    },
    async getCountersAdminRights({ commit }, { counter }) {
      const { data: countersAdminRights } = await paramsServices.getCountersAdminRights(counter);
      commit('setCountersAdminRights', countersAdminRights);
      return countersAdminRights;
    },
    async editCountersAdminRights({ commit }, { idAdmin, counter, accessLevel }) {
      const { data: editresp } = await paramsServices
        .editCountersAdminRights(idAdmin, counter, accessLevel);
      if (editresp.message.ok) {
        commit('editCountersAdminRights', { idAdmin, counter, accessLevel });
      }
    },
    async createCountersAdminRights({ commit }, rights) {
      const { data: countersAdminRights } = await paramsServices.createCountersAdminRights(rights);
      commit('addCountersAdminRights', countersAdminRights);
    },
    async deleteCountersAdminRights({ commit }, { idAdmin, counter }) {
      const { data: editDelete } = await paramsServices
        .deleteCountersAdminRights(idAdmin, counter);
      if (editDelete.message.ok) {
        commit('deleteCountersAdminRights', { idAdmin, counter });
      }
    },

  },
};
