import counterDatasServices from '@/services/counterDatas.services';
import counterHistoryDatasServices from '@/services/counterHistoryDatas.services';
import countersDiagramDatasServices from '@/services/countersDiagramDatas.services';

export default {
  state: {
    counterDatas: {},
    lastDataDate: {},
    historyData: [],
    diagramData: [],
  },
  getters: {
    counterDatas: (state) => state.counterDatas,
    historyData: (state) => state.historyData,
    lastDataDate: (state) => state.lastDataDate,
    diagramData: (state) => state.diagramData,
  },
  mutations: {
    setgetHistoricalDatas(state, data) {
      state.counterDatas = { ...data };
    },
    setLastDataDate(state, data) {
      state.lastDataDate = data;
    },
    setHistoryData(state, data) {
      state.historyData = [...data];
    },
    setDiagramData(state, data) {
      state.diagramData = [...data];
    },
  },
  actions: {
    async getHistoricalDatas({ commit }, {
      idCounter, startDate, endDate, limit, page,
    }) {
      const { data } = await counterDatasServices.getHistoricalDatas({
        idCounter,
        startDate,
        endDate,
        limit,
        page,
      });
      commit('setgetHistoricalDatas', data);
    },
    async getLastDataDate({ commit }, idCounter) {
      commit('setLastDataDate', {});
      const { data } = await counterDatasServices.getLastDataDate(idCounter);
      commit('setLastDataDate', data);
      return data;
    },
    async getCounterHistoryDatas({ commit }, idCounter) {
      commit('setHistoryData', []);
      const { data } = await counterHistoryDatasServices.getHistoryDatas(idCounter);
      commit('setHistoryData', data);
    },
    async getCountersDiagramDatas({ commit }, { idCounter, range }) {
      commit('setDiagramData', []);
      const { data } = await countersDiagramDatasServices.getCountersDiagramDatas(idCounter, range);
      commit('setDiagramData', data);
    },
  },
};
