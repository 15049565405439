import Api from '@/services/Api';

export default {
  getThemes() {
    return Api().get('params/themes');
  },
  createTheme(theme) {
    return Api().post('params/themes', theme);
  },
  updateTheme(id, theme) {
    return Api().put(`params/themes/${id}`, theme);
  },
  setThemeToUsers(payload) {
    return Api().post('params/usersThemes', payload);
  },
  createCanbusConfig(config) {
    return Api().post('params/canbusconfig', config);
  },
  getCanbusConfig() {
    return Api().get('params/canbusconfig');
  },
  createCanbusValuesConfig(config) {
    return Api().post('params/canbusvaluesconfig', config);
  },
  getCanbusValuesConfig() {
    return Api().get('params/canbusvaluesconfig');
  },
  createAdminAccount(account) {
    return Api().post('params/adminAccount', account);
  },
  getAdminAccounts() {
    return Api().get('params/adminAccount');
  },
  addEmailInAdminAccounts(id, emails) {
    return Api().post(`params/usersAdminAccount/${id}`, { emails });
  },
  getFirmwareList() {
    return Api().get('params/firmwareLists');
  },
  getCountersAdminRights(counter) {
    return Api().get('params/countersAdminRights', { params: { counter } });
  },
  editCountersAdminRights(idAdmin, counter, accessLevel) {
    return Api().patch(
      'params/countersAdminRights',
      { access_level: accessLevel },
      { params: { counter, idAdmin } },
    );
  },
  createCountersAdminRights(rights) {
    return Api().post(
      'params/countersAdminRights',
      rights,
    );
  },
  deleteCountersAdminRights(idAdmin, counter) {
    return Api().delete(
      'params/countersAdminRights',
      { params: { counter, idAdmin } },
    );
  },
};
