import Api from '@/services/Api';

export default {
  getICCID(idCounter) {
    return Api().get('iccid', {
      params: {
        idCounter,
      },
    });
  },
};
