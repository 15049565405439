import Api from '@/services/Api';

export default {
  getHistoricalDatasCanbusRaw({
    idCounter, startDate, endDate, limit, page,
  }) {
    return Api().get('canbusRaw', {
      params: {
        idCounter, startDate, endDate, limit, page,
      },
    });
  },
};
