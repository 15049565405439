import axios from 'axios';

const url = process.env.NODE_ENV === 'production' ? 'https://admin.samsys.io/api/admin' : 'http://127.0.0.1:5001/admin';

export default {
  askReset(email) {
    return axios.post(`${url}/auth/askReset`, { email });
  },
  resetPassword(resetPayload) {
    return axios.post(`${url}/auth/resetPassword`, resetPayload);
  },
};
