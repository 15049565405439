import moment from 'moment';
import lastCanbusValuesServices from '../services/lastCanbusValues.services';

export default {
  state: {
    lastCanbusValues: {},
  },
  getters: {
    lastCanbusValues: (state) => {
      const { configs } = state.lastCanbusValues;
      const locale = moment.locale();

      if (configs) {
        return configs.map((elem) => {
          const name = elem.name.find((tps) => tps.languages.includes(locale))
            || elem.name.find((tps) => tps.languages.includes('default'));
          const unit = elem.unit.find((tps) => tps.languages.includes(locale))
            || elem.unit.find((tps) => tps.languages.includes('default'));

          // eslint-disable-next-line camelcase
          const { value, t_data } = elem.lastData || {};

          return {
            name: name ? name.text : '',
            unit: unit ? unit.text : '',
            id: elem.id,
            value,
            t_data,
            write: elem.access_levels.write,
            request: elem.access_levels.request,
          };
        });
      }
      return [];
    },
  },
  mutations: {
    setLastCanbusValues(state, lastData) {
      state.lastCanbusValues = { ...lastData };
    },
  },
  actions: {
    async getLastCanbusValues({ commit }, idCounter) {
      const { data } = await lastCanbusValuesServices.getLastCanbusValues(idCounter);
      commit('setLastCanbusValues', data);
    },
  },
};
