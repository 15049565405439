import Api from '@/services/Api';

export default {
  getCanbusValuesConfigs({ idCounter }) {
    return Api().get('canbusValuesConfigs', {
      params: {
        idCounter,
      },
    });
  },
  setCanbusValuesConfigs({ counters, idCanbusValuesConfig }) {
    return Api().post('canbusValuesConfigs', {
      counters,
      idCanbusValuesConfig,
    });
  },
};
