import authServices from '../services/auth.services';

export default {
  state: {
    adminToken: localStorage.getItem('adminJwt') || null,
  },
  getters: {
    getAdminToken: (state) => state.adminToken,
    getAdminHeaders: (state) => ({ Authorization: `JWT ${state.adminToken}` }),
  },
  mutations: {
    setAdminToken(state, token) {
      state.adminToken = token;
    },
  },
  actions: {
    setAdminToken({ commit }, token) {
      localStorage.setItem('adminJwt', token);
      commit('setAdminToken', token);
    },
    removeAdminToken({ commit }) {
      localStorage.removeItem('adminJwt');
      commit('setAdminToken', null);
    },
    // eslint-disable-next-line no-empty-pattern
    async askResetPassword({}, email) {
      await authServices.askReset(email);
    },
    // eslint-disable-next-line no-empty-pattern
    async resetPassword({}, { token, password }) {
      await authServices.resetPassword({ token, password });
    },
  },
};
