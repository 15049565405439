import iccidServices from '@/services/iccid.services';

export default {
  state: {
    iccid: [],
  },
  getters: {
    iccid: (state) => state.iccid,
  },
  mutations: {
    setICCID(state, iccid) {
      state.iccid = [...iccid];
    },
  },
  actions: {
    async getICCID({ commit }, idCounter) {
      commit('setICCID', []);
      const { data: iccid } = await iccidServices.getICCID(idCounter);
      commit('setICCID', iccid);
    },
  },
};
