import Api from '@/services/Api';

export default {
  getHistoricalDatasCanbusJ1939({
    idCounter, startDate, endDate, limit, page,
  }) {
    return Api().get('canbusJ1939Momentary', {
      params: {
        idCounter, startDate, endDate, limit, page,
      },
    });
  },
};
