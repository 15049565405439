import iccidUsageServices from '@/services/iccidUsage.services';

export default {
  state: {
    iccidUsage: [],
  },
  getters: {
    iccidUsage: (state) => state.iccidUsage,
  },
  mutations: {
    setICCIDUsage(state, iccidUsage) {
      state.iccidUsage = [...iccidUsage];
    },
  },
  actions: {
    async getICCIDUsage({ commit }, idCounter) {
      commit('setICCIDUsage', []);
      const { data: iccidUsage } = await iccidUsageServices.getICCIDUsage(idCounter);
      commit('setICCIDUsage', iccidUsage);
    },
  },
};
