import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import fr from 'vuetify/es5/locale/fr';
import en from 'vuetify/es5/locale/en';

Vue.use(Vuetify);

const locale = navigator.language.substring(0, 2).toLowerCase() === 'fr' ? 'fr' : navigator.language.substring(0, 2).toLowerCase();

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#252A34',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
      },
    },
  },
  lang: {
    locales: { fr, en },
    current: locale,
  },
});
