import countersClusterServices from '@/services/countersCluster.services';
import countersInvoicesServices from '@/services/counters.services';
import countersMachinesServices from '@/services/countersMachines.services';

export default {
  state: {
    associations: [],
    counterMachines: [],
  },
  getters: {
    associations: (state) => state.associations,
    counterMachines: (state) => state.counterMachines,
  },
  mutations: {
    setCounterAssociations(state, associations) {
      state.associations = [...associations];
    },
    setCounterMachines(state, associations) {
      state.counterMachines = [...associations];
    },
    addCounterMachines(state, associations) {
      const tps = [...(state.associations)];

      associations.forEach((association) => {
        const test = tps.find((elem) => elem.id === association.counter);

        if (test) {
          if (!test.owner) {
            test.owner = [];
          }
          test.owner.push({
            cluster: association.cluster,
            start_date: association.startDate,
            end_date: association.endDate,
          });
        }
      });

      state.associations = [...tps];
    },
  },
  actions: {
    async getCounterAssociations({ commit }, { idCounter }) {
      commit('setCounterAssociations', []);
      const { data: associations } = await countersClusterServices.getCountersCluster(idCounter);
      commit('setCounterAssociations', associations);
    },
    async getCountersMachines({ commit }, { idCounter }) {
      commit('setCounterMachines', []);
      const { data: associations } = await countersMachinesServices.getCountersMachines(idCounter);
      commit('setCounterMachines', associations);
    },
    async addCounterAssociations({ commit }, countersClusters) {
      await countersClusterServices
        .addCountersCluster(countersClusters.map((elem) => ({
          counter: elem.counter,
          cluster: elem.cluster,
          startDate: elem.startDate,
          endDate: elem.endDate,
        })));
      commit('addCounterMachines', countersClusters);
    },
    // eslint-disable-next-line no-empty-pattern
    async addCounterInvoice({}, lesDatas) {
      console.log(lesDatas, 'mael');
      await countersInvoicesServices
        .addCountersInvoice(lesDatas.map((elem) => ({
          counter: elem.counter,
          startDate: elem.startDate,
          customer: elem.customer,
          invoice: elem.invoice,
        })));
    },
    // eslint-disable-next-line no-empty-pattern
    async addBillingLicence({}, lesDatas) {
      // console.log(lesDatas, 'mael');
      await countersInvoicesServices
        .addBillingLicences(lesDatas.map((elem) => ({
          start: elem.start,
          period: elem.period,
          price: elem.price,
          billing_counters: elem.billing_counters,
          auto_renew: elem.auto_renewd,
          customer: elem.customer,
          licence_valide: elem.licence_valide,
          invoices: elem.invoices,
        })));
    },
  },
};
