import Vue from 'vue'
import VueShepherd from 'vue-shepherd'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import vuetify from './plugins/vuetify'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import 'leaflet/dist/leaflet.css'
import 'shepherd.js/dist/css/shepherd.css'
import dayjs from 'dayjs'
const localizedFormat = require('dayjs/plugin/localizedFormat')
Vue.config.productionTip = false
Vue.config.devtools = true
const moment = require('moment')
require('moment/locale/fr')
require('dayjs/locale/fr')

const locale =
  navigator.language.substring(0, 2).toLowerCase() === 'fr'
    ? 'fr'
    : navigator.language.substring(0, 2).toLowerCase()
dayjs.extend(localizedFormat)
dayjs.locale(locale)
moment.locale(locale)
Vue.use(require('vue-moment'), {
  moment,
})

Vue.use(VueShepherd)

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
