import CanbusRawServices from '../services/canbusRaw.services';

export default {
  state: {
    canbusRaw: [],
  },
  getters: {
    canbusRaw: (state) => state.canbusRaw,
  },
  mutations: {
    setCanbusRaw(state, data) {
      state.canbusRaw = [...data];
    },
  },
  actions: {
    async getHistoricalDatasCanbusRaw({ commit }, {
      idCounter, startDate, endDate, limit, page,
    }) {
      commit('setCanbusRaw', []);
      const { data } = await CanbusRawServices.getHistoricalDatasCanbusRaw({
        idCounter,
        startDate,
        endDate,
        limit,
        page,
      });
      commit('setCanbusRaw', data);
    },
  },
};
