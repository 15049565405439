import Api from '@/services/Api';

export default {
  getCanbusValues({
    idCounter, startDate, endDate, limit, page,
  }) {
    return Api().get('canbusValues', {
      params: {
        idCounter, startDate, endDate, limit, page,
      },
    });
  },
  requestCanbusValues({
    idCounter, idCanbusValuesConfig,
  }) {
    return Api().get(`canbusValues/request/${idCanbusValuesConfig}`, {
      params: {
        idCounter,
      },
    });
  },
  writeCanbusValues(idCanbusValuesConfig, { idCounter, data }) {
    return Api().post(`canbusValues/write/${idCanbusValuesConfig}`, {
      idCounter,
      data,
    });
  },
};
