const mqtt = require('mqtt');

const options = {
  // host: 'ws://broker.mqttdashboard.com:8000/mqtt', // 'wss://aea52bd57299477e8a3f55b263c619b4.s1.eu.hivemq.cloud',
  host: 'wss://admin.samsys.io',
  port: 9001,
  protocol: 'wss',
  username: localStorage.getItem('adminJwt'),
  password: localStorage.getItem('adminJwt'),
// clientId: 'web',
};

// initialize the MQTT client
console.log('mqtt.js connect');
const clientMQTT = mqtt.connect(options.host, options);

// subscribe to topic 'my/test/topic'
// clientMQTT.subscribe('counter/#');
export default {
  clientMQTT,
  mqtt,
};
// publish message 'Hello' to topic 'my/test/topic'
