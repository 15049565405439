/* eslint-disable no-unused-vars */
import countersServices from '@/services/counters.services';

export default {
  state: {
    counters: [],
    countersLight: [],
    counterInfos: {},
  },
  getters: {
    counters: (state) => state.counters,
    countersLight: (state) => state.countersLight,
    counterInfos: (state) => state.counterInfos,
  },
  mutations: {
    setCounters(state, counters) {
      state.counters = [...counters];
    },
    addCounter(state, counters) {
      state.counters.push(...counters);
    },
    setCountersLight(state, counters) {
      state.countersLight = [...counters].sort((a, b) => (a.id > b.id ? 1 : -1));
    },
    setCounterInfos(state, aCounter) {
      state.counterInfos = aCounter;
    },
  },
  actions: {
    async getCounters({ commit }) {
      const { data: counters } = await countersServices.getCounters();
      commit('setCounters', counters);
    },
    async getCountersPaginated({ commit }) {
      let page = 1;
      const itemsPerPage = 700;
      const { data: counters } = await countersServices.getCounters({ page: 1, itemsPerPage });
      commit('setCounters', counters);

      let tps = { data: counters };

      while (tps.data.length) {
        page += 1;
        // eslint-disable-next-line no-await-in-loop
        tps = await countersServices
          .getCounters({ page, itemsPerPage });

        if (tps.data.length) {
          commit('addCounter', tps.data);
        }
      }
    },
    async getCountersLight({ commit }) {
      const { data: counters } = await countersServices.getCountersLight();
      commit('setCountersLight', counters);
    },
    async getCountersInfos({ commit }, { idCounter }) {
      commit('setCounterInfos', {});
      const { data: aCounter } = await countersServices.getCountersInfos(idCounter);
      commit('setCounterInfos', aCounter);
    },
    async setCountersInfos({ commit }, { idCounter, payload }) {
      const { data: aCounter } = await countersServices.setCountersInfos(idCounter, payload);
      commit('setCounterInfos', aCounter);
    },
  },
};
