import clustersServices from '@/services/clusters.services';

export default {
  state: {
    clusters: [],
    clusterInfos: {},
  },
  getters: {
    clusters: (state) => state.clusters
      .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)),
    clusterInfos: (state) => state.clusterInfos,
  },
  mutations: {
    setClusters(state, clusters) {
      state.clusters = [...clusters];
    },
    setClusterInfos(state, aCluster) {
      state.clusterInfos = aCluster;
    },
    addCluster(state, cluster) {
      state.clusters.push(cluster);
    },
  },
  actions: {
    async getClusters({ commit }) {
      const { data: clusters } = await clustersServices.getClusters();
      commit('setClusters', clusters);
    },
    async getClustersInfos({ commit }, { idCluster }) {
      commit('setClusterInfos', {});
      const { data: aCluster } = await clustersServices.getClustersInfos(idCluster);
      commit('setClusterInfos', aCluster);
    },
    async createCluster({ commit }, cluster) {
      const { data: aCluster } = await clustersServices.createClusters(cluster);
      commit('addCluster', aCluster);
      return aCluster;
    },
  },
};
